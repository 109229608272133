<template>
  <div
    v-if="inlineChecklist"
    class="InlineChecklist"
  >
    <v-tabs
      v-model="currentTab"
      class="InlineChecklist__tabs"
      active-class="InlineChecklist__tab--active"
    >
      <v-tabs-slider />

      <v-tab
        :key="TABS.info"
        :href="`#${TABS.info}`"
        class="InlineChecklist__tab"
      >
        {{ $t('checklist.Info') }}
      </v-tab>

      <div class="InlineChecklist__actions">
        <v-btn
          :disabled="previousRoute === null"
          text
          :height="TABS_HEIGHT"
          :width="TABS_HEIGHT"
          :min-width="TABS_HEIGHT"
          class="InlineChecklist__btn hidden-md-and-down"
          :to="previousRoute"
          replace
          exact
        >
          <v-icon size="16">
            $arrow-left
          </v-icon>
        </v-btn>
        <v-btn
          :disabled="nextRoute === null"
          text
          :height="TABS_HEIGHT"
          :width="TABS_HEIGHT"
          :min-width="TABS_HEIGHT"
          :to="nextRoute"
          replace
          exact
        >
          <v-icon size="16">
            $arrow-right
          </v-icon>
        </v-btn>
      </div>
    </v-tabs>

    <v-tabs-items
      v-model="currentTab"
      class="InlineChecklist__tabs-items"
    >
      <!-- details/description tab content -->
      <v-tab-item
        :key="TABS.info"
        :value="TABS.info"
        class="InlineChecklist__tab-item"
      >
        <v-fade-transition mode="out-in">
          <v-card
            ref="checklistInfo"
            flat
            tile
            class="InlineChecklist__card InlineChecklist__info"
            :style="cardStyle"
          >
            <h2 class="InlineChecklist__title">
              {{ inlineChecklist.name }}
            </h2>
            <div class="InlineChecklist__toc">
              <a
                v-for="tocItem in toc"
                :key="tocItem.href"
                :href="tocItem.href"
                class="InlineChecklist__toc-item"
                v-text="tocItem.text"
              />
            </div>

            <h2
              v-if="inlineChecklist.description"
              id="checklist-description"
              class="InlineChecklist__subtitle"
              v-text="$t('checklist.Description')"
            />
            <AppMarkdownViewer
              v-if="inlineChecklist.description"
              id="checklist-general-description"
              class="InlineChecklist__paragraph"
              :source="inlineChecklist.description"
              gallery="InlineChecklist"
            />

            <div
              v-if="inlineChecklist.resolution"
              class="InlineChecklist__quote"
            >
              <h2
                class="InlineChecklist__subtitle"
                v-text="$t('checklist.Result')"
              />
              <AppMarkdownViewer
                class="InlineChecklist__paragraph"
                :source="inlineChecklist.resolution"
                gallery="InlineChecklist"
              />
            </div>

            <h2
              v-if="inlineChecklist.connectedNodes.length"
              id="checklist-assigned-objects"
              class="InlineChecklist__subtitle"
              v-text="$t('checklist.AssignedObjects')"
            />
            <ChecklistAssignedObjects
              v-for="object in inlineChecklist.connectedIps"
              :key="object.connectedUuid"
              :object="object"
            />

            <v-btn
              v-if="scrollpx >= 300"
              class="InlineChecklist__button-top"
              icon
              tile
              @click="$refs.checklistInfo.$el.scrollTop = 0"
            >
              <v-icon color="#A3B0C2">
                mdi-chevron-up
              </v-icon>
            </v-btn>
          </v-card>
        </v-fade-transition>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ChecklistAssignedObjects from '@/components/ChecklistAssignedObjects'
import * as R from 'ramda'

const TABS = Object.freeze({
  info: 'info-tab',
  chat: 'chat-tab',
})

export default {
  name: 'InlineChecklist',

  components: {
    ChecklistAssignedObjects,
  },

  props: {
    projectId: { type: String, required: true },
    checklistId: { type: String, required: true },
    flattenChecklistUuid: { type: Array, required: false },
  },

  data() {
    return {
      TABS,
      TABS_HEIGHT: 48,
      currentTab: TABS.info,

      showDetails: true,
      summaryHeight: 125,

      scrollpx: 0,
    }
  },

  computed: {
    currentPosition() { // Maybe<int>
      const { $route, flattenChecklistUuid } = this

      if (!flattenChecklistUuid) return null
      const pos = flattenChecklistUuid.indexOf($route.params.checklistId)
      return pos === -1 ? null : pos
    },

    previousRoute() {
      const { $route, flattenChecklistUuid, currentPosition: ix } = this
      if (!flattenChecklistUuid || !ix || ix <= 0) return null

      const previousChecklistId = flattenChecklistUuid[ix - 1]
      return R.pipe(
        R.assocPath(['params', 'checklistId'], previousChecklistId),
        R.pick(['name', 'params', 'checklistId']),
      )($route)
    },

    nextRoute() {
      const { $route, flattenChecklistUuid, currentPosition: ix } = this
      if (!flattenChecklistUuid || ix === null || ix >= (flattenChecklistUuid.length - 1)) return null

      const previousChecklistId = flattenChecklistUuid[ix + 1]
      return R.pipe(
        R.assocPath(['params', 'checklistId'], previousChecklistId),
        R.pick(['name', 'params', 'checklistId']),
      )($route)
    },

    inlineChecklist() {
      const { checklistId } = this
      return this.$store.getters['checklist/get'](checklistId)
    },

    toc() {
      return [
        { text: this.$t('checklist.AssignedObjects'), href: '#checklist-assigned-objects' },
        // { text: 'Resolution', href: '#checklist-resolution' },
      ]
        .filter(Boolean)
    },

    occupiedVerticalSpace() {
      const { TABS_HEIGHT } = this
      const { smAndDown } = this.$vuetify.breakpoint

      const appBarHeight = smAndDown ? 56 : 64
      return appBarHeight + TABS_HEIGHT
    },

    cardStyle() {
      const { occupiedVerticalSpace, showDetails, summaryHeight } = this
      return {
        minHeight: `calc(100vh - ${occupiedVerticalSpace}px)`,
        maxHeight: `calc(100vh - ${occupiedVerticalSpace}px)`,
        opacity: showDetails ? null : '0',
        scrollPaddingTop: `${summaryHeight}px`,
      }
    },
  },

  mounted() {
    setTimeout(() => {
      document.getElementsByClassName('InlineChecklist__info')[0].addEventListener('scroll', this.handleScroll)
    }, 100)
  },

  methods: {
    handleScroll() {
      this.scrollpx = document.getElementsByClassName('InlineChecklist__info')[0].scrollTop
    },
  },
}
</script>

<style lang="sass" scoped>
  @import '../scss/mixins'

  .InlineChecklist
    height: 100%
    display: flex
    flex-direction: column
    justify-content: stretch
    overflow-y: hidden

    &__title
      font-weight: 500
      font-size: 24px
      line-height: 32px
      margin-top: 32px

    &__tabs
      margin-top: -1px
      border-bottom: 1px solid #F0F1F3

      // force remove arrows from tabs
      ::v-deep .v-slide-group__prev,
      ::v-deep .v-slide-group__next
        display: none !important
      ::v-deep .v-tabs-bar
        border-top: 1px solid #f0f1f3

    &__tab, &__tab--active // change tab captions appearance
      text-transform: none
      color: #8B90A0
      font-weight: 400
      font-size: 14px
    &__tab--active
        color: #3C3A52

    &__actions
      margin-left: auto
      padding-right: 4px

    &__tabs-items
      display: flex
      flex-direction: column
      flex-wrap: nowrap
      height: 100%
      color: #3C3A52

    &__btn
      color: #8B90A0
      background: white
      margin-left: auto

    &__card
      overflow-x: hidden
      overflow-y: auto

    &__button-top
      position: fixed
      left: 20px
      bottom: 20px
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.08)
      background: #ffffffcc

    &__info
      padding: 0 32px 32px

    &__toc
      font-size: 14px
      line-height: 24px
      font-weight: 400
      margin: 12px 0 24px
      display: flex

    &__toc-item
      display: block
      text-decoration: none
      margin-right: 12px
      &:hover, &:active, &:focus
        text-decoration: underline

    &__subtitle
      font-style: normal
      font-weight: 500
      font-size: 18px
      line-height: 28px
      margin-top: 13px
      margin-bottom: 8px

    &__paragraph
      margin-top: 8px
      margin-bottom: 13px
      font-size: 14px
      line-height: 20px

    &__quote
      background: #FAFAFA
      padding: 8px 0 8px 16px
      border-radius: 4px
      box-shadow: -4px 0px 0px 0px #F7E9A0
</style>
